<vex-page-layout-header class="pb-16 flex flex-col items-start justify-center">
    <div [class.container]="layoutCtrl.value === 'boxed'" [class.px-gutter]="layoutCtrl.value === 'fullwidth'"
        class="w-full flex flex-col sm:flex-row justify-between">
        <div>
            <h1 class="title mt-0 mb-1">{{pageHeader}}</h1>
            <vex-breadcrumbs [crumbs]="crumbValue"></vex-breadcrumbs>
        </div>
        <button *ngIf="backButtonVisible" class="ml-2 pb-2" color="primary" (click)="goBack()" mat-icon-button type="button">
            <mat-icon svgIcon="mat:arrow_back" matTooltip="Back"></mat-icon>
        </button>
        <medmio-ui-admin-dropdown *ngIf="orgDropDownVisible"></medmio-ui-admin-dropdown>
    </div>
</vex-page-layout-header>