import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map, Observable, throwError } from "rxjs";
import { ApiUrls } from "src/app/config/api-url-config";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class CommonService {
  constructor(private httpClient: HttpClient) {}

  getUserInfo():Observable<any>{
    return this.httpClient
      .get(environment.identity() + ApiUrls.userInfoGet())
      .pipe(
        map((body: any) => body),
        catchError((error: any) =>
          throwError(error || "Error, while loading data ")
        )
      );
  }
}
