import { Component, OnInit } from "@angular/core";
import { AdminServiceService } from "../../services/admin-service.service";
import { OrganizationCrudService } from "../../services/api-services/organization-crud.service";
import { UsersService } from "../../services/api-services/users.service";

@Component({
    selector: "medmio-ui-admin-dropdown",
    templateUrl: "./admin-dropdown.component.html",
    styleUrls: ["./admin-dropdown.component.scss"],
})
export class AdminDropdownComponent implements OnInit {
    isAdmin: boolean;
    selectedOrganization = "";
    orgList: any = [];
    constructor(
        private adminService: AdminServiceService,
        private orgService: OrganizationCrudService,
        private userService: UsersService
    ) {}

    ngOnInit(): void {
        this.isAdminCheck();
        this.adminService.organizationIdSubject.subscribe((res) => {
            this.selectedOrganization = res;
        });
    }
    isAdminCheck() {
        this.adminService.isAdmin$.subscribe((res) => {
            this.isAdmin = res;
            if (this.isAdmin) {
                this.getOrganizationKeyValue();
            }
        });
    }

    getOrganizationKeyValue() {
        // this.orgService.OrganizationGetKeyValueApi().subscribe(
        //     (res) => {
        //         if (!res.IsError) {
        //             this.orgList = res.Result;
        //         } else {
        //         }
        //     },
        //     (err) => {}
        // );
        this.adminService.organizationOptions.subscribe(res=>this.orgList=res)
    }

    changeOrganization() {
        sessionStorage.setItem("orgId", this.selectedOrganization);
        this.adminService.organizationIdSubject.next(this.selectedOrganization);
    }
}
