import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from "./layout/layout.module";
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from "@angular/material/checkbox";
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { SpinnerComponent } from './spinner/spinner.component';
import { RecordNotFoundComponent } from './components/record-not-found/record-not-found.component';
import { PageLayoutModule } from './components/page-layout/page-layout.module';
import { SecondaryToolbarModule } from './components/secondary-toolbar/secondary-toolbar.module';
import { BreadcrumbsModule } from './components/breadcrumbs/breadcrumbs.module';
import { ChartModule } from './components/chart/chart.module';
import { ConfigPanelModule } from './components/config-panel/config-panel.module';
import { HighlightModule } from './components/highlight/highlight.module';
import { MegaMenuModule } from './components/mega-menu/mega-menu.module';
import { NavigationItemModule } from './components/navigation-item/navigation-item.module';
import { PopoverModule } from './components/popover/popover.module';
import { ProgressBarModule } from './components/progress-bar/progress-bar.module';
import { ScrollbarModule } from './components/scrollbar/scrollbar.module';
import { SearchModule } from './components/search/search.module';
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatMenuModule } from "@angular/material/menu";
import { MatTableModule } from "@angular/material/table";
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { InnerSpinnerComponent } from './inner-spinner/inner-spinner.component';
import { MatExpansionModule } from "@angular/material/expansion";
import {MatCardModule} from '@angular/material/card';
import { MatListModule } from "@angular/material/list";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatChipsModule } from "@angular/material/chips";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { AdminDropdownComponent } from './admin-dropdown/admin-dropdown.component';
import { MatBadgeModule } from "@angular/material/badge";
import { WidgetAssistantModule } from './components/widgets/widget-assistant/widget-assistant.module';
import { WidgetLargeChartModule } from './components/widgets/widget-large-chart/widget-large-chart.module';
import { WidgetLargeGoalChartModule } from './components/widgets/widget-large-goal-chart/widget-large-goal-chart.module';
import { WidgetQuickLineChartModule } from './components/widgets/widget-quick-line-chart/widget-quick-line-chart.module';
import { WidgetQuickValueCenterModule } from './components/widgets/widget-quick-value-center/widget-quick-value-center.module';
import { WidgetQuickValueStartModule } from './components/widgets/widget-quick-value-start/widget-quick-value-start.module';
import { WidgetTableModule } from './components/widgets/widget-table/widget-table.module';
import { PageHeaderComponent } from './page-header/page-header.component';
// import { MatChipsModule } from "@angular/material/chips";
@NgModule({
    declarations: [
        SpinnerComponent,
        RecordNotFoundComponent,
        InnerSpinnerComponent,
        AdminDropdownComponent,
        PageHeaderComponent,
    ],
    imports: [
        CommonModule,
        LayoutModule,
        ReactiveFormsModule,
        FormsModule,
        PageLayoutModule,
        SecondaryToolbarModule,
        BreadcrumbsModule,
        ChartModule,
        ConfigPanelModule,
        HighlightModule,
        MegaMenuModule,
        NavigationItemModule,
        PageLayoutModule,
        PopoverModule,
        ProgressBarModule,
        ScrollbarModule,
        SearchModule,
        // Material Modules
        MatSlideToggleModule,
        MatInputModule,
        MatIconModule,
        MatSnackBarModule,
        MatTooltipModule,
        MatButtonModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatButtonToggleModule,
        MatMenuModule,
        MatTableModule,
        MatSelectModule,
        MatPaginatorModule,
        MatDividerModule,
        MatDialogModule,
        MatExpansionModule,
        MatCardModule,
        MatListModule,
        DragDropModule,
        MatRadioModule,
        MatSliderModule,
        MatDatepickerModule,
        MatAutocompleteModule,
        MatGridListModule,
        MatChipsModule,
        ClipboardModule,
        MatBadgeModule,
        WidgetAssistantModule,
        WidgetLargeChartModule,
        WidgetLargeGoalChartModule,
        WidgetQuickLineChartModule,
        WidgetQuickValueCenterModule,
        WidgetQuickValueStartModule,
        WidgetTableModule
    ],
    exports: [
        LayoutModule,
        ReactiveFormsModule,
        FormsModule,
        SpinnerComponent,
        RecordNotFoundComponent,
        PageLayoutModule,
        BreadcrumbsModule,
        ChartModule,
        ConfigPanelModule,
        HighlightModule,
        MegaMenuModule,
        NavigationItemModule,
        PageLayoutModule,
        PopoverModule,
        ProgressBarModule,
        ScrollbarModule,
        SearchModule,
        SecondaryToolbarModule,
        // Material Modules
        MatSlideToggleModule,
        MatInputModule,
        MatIconModule,
        MatSnackBarModule,
        MatTooltipModule,
        MatButtonModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatButtonToggleModule,
        MatMenuModule,
        MatTableModule,
        MatSelectModule,
        MatPaginatorModule,
        MatDividerModule,
        MatDialogModule,
        InnerSpinnerComponent,
        MatExpansionModule,
        MatCardModule,
        MatListModule,
        DragDropModule,
        MatRadioModule,
        MatSliderModule,
        MatDatepickerModule,
        MatAutocompleteModule,
        MatGridListModule,
        MatChipsModule,
        ClipboardModule,
        AdminDropdownComponent,
        MatBadgeModule,
        WidgetAssistantModule,
        WidgetLargeChartModule,
        WidgetLargeGoalChartModule,
        WidgetQuickLineChartModule,
        WidgetQuickValueCenterModule,
        WidgetQuickValueStartModule,
        WidgetTableModule,
        PageHeaderComponent

    ],
    providers: [
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: "fill",
            } as MatFormFieldDefaultOptions,
        },
    ],
})
export class ThemeModule {}
