import { NgModule } from '@angular/core';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ThemeModule } from './theme/theme.module';



@NgModule({
  declarations: [
  ],
  imports: [CommonModule, ThemeModule],
  exports: [ThemeModule],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
})
export class SharedModule {}
