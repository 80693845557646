export const ApiUrls = {
    // Language

    LanguageGetAll: () => `/portal/api/Language/LanguageGetAll`,
    LanguageAdd: () => `/portal/api/Language/LanguageAdd`,
    LanguageGetById: (languageId: any) =>
        `/portal/api/Language/LanguageGetById/${languageId}`,
    LanguageRemove: (languageId: any) =>
        `/portal/api/Language/LanguageRemove/${languageId}`,
    LanguageGetByName: (languageName: string) =>
        `/portal/api/Language/LanguageGetByName/${languageName}`,
    LanguageGetKeyPairValues: () =>
        `/portal/api/Language/LanguageGetKeyPairValues`,

    //EmrTypes

    EmrTypesGetAll: () => `/portal/api/EmrTypes/EmrTypesGetAll`,
    EmrTypesAdd: () => `/portal/api/EmrTypes/EmrTypesAdd`,
    EmrTypesGetById: (emrTypeId: any) =>
        `/portal/api/EmrTypes/EmrTypesGetById/${emrTypeId}`,
    EmrTypesRemove: (emrTypeId: any) =>
        `/portal/api/EmrTypes/EmrTypesRemove/${emrTypeId}`,
    EmrTypesGetByName: (emrTypeName: any) =>
        `/portal/api/EmrTypes/EmrTypesGetByName/${emrTypeName}`,
    EmrTypesGetKeyPairValues: () =>
        `/portal/api/EmrTypes/EmrTypesGetKeyPairValues`,

    // GroupTypes

    GroupTypesGetAll: () => `/portal/api/FavouriteTypes/FavouriteTypesGetAll`,
    GroupTypesAdd: () => `/portal/api/FavouriteTypes/FavouriteTypesAdd`,
    GroupTypesGetById: (groupTypeId: number) =>
        `/portal/api/FavouriteTypes/FavouriteTypesGetById/${groupTypeId}`,
    GroupTypesRemove: (groupTypeId: number) =>
        `/portal/api/FavouriteTypes/FavouriteTypesRemove/${groupTypeId}`,
    GroupTypesGetKeyPairValues: () =>
        `/portal/api/FavouriteTypes/FavouriteTypesGetKeyPairValues`,

    // LocationTypes

    PlaceOfServiceGetAll: () =>
        `/portal/api/PlaceOfService/PlaceOfServiceGetAll`,
    PlaceOfServiceAdd: () => `/portal/api/PlaceOfService/PlaceOfServiceAdd`,
    PlaceOfServiceGetById: (locationTypeId: any) =>
        `/portal/api/PlaceOfService/PlaceOfServiceGetById/${locationTypeId}`,
    PlaceOfServiceRemove: (locationTypeId: any) =>
        `/portal/api/PlaceOfService/PlaceOfServiceRemove/${locationTypeId}`,
    PlaceOfServiceGetKeyPairValues: () =>
        `/portal/api/PlaceOfService/PlaceOfServiceGetKeyPairValues`,

    // CptCode

    CptCodeGetAll: () => `/portal/api/CptCode/CptCodeGetAll`,
    CptCodeAdd: () => `/portal/api/CptCode/CptCodeAdd`,
    CptCodeCount: (code) =>
        `/portal/api/CptCode/CptCodeGetCptCount?code=${code}`,
    CptCodeGetById: (cptCodeId: any) =>
        `/portal/api/CptCode/CptCodeGetById/${cptCodeId}`,
    CptCodeRemove: (cptCodeId: any) =>
        `/portal/api/CptCode/CptCodeRemove/${cptCodeId}`,
    CptCodeGetKeyPairValues: (code) =>
        `/portal/api/CptCode/CptCodeGetKeyPairValues/${code}`,

    // IcdCode

    IcdCodeGetAll: () => `/portal/api/IcdCode/IcdCodeGetAll`,
    IcdCodeAdd: () => `/portal/api/IcdCode/IcdCodeAdd`,
    IcdCodeCount: (code) =>
        `/portal/api/IcdCode/IcdCodeGetIcdCount?code=${code}`,
    IcdCodeGetById: (icdCodeId: any) =>
        `/portal/api/IcdCode/IcdCodeGetById/${icdCodeId}`,
    IcdCodeRemove: (icdCodeId: any) =>
        `/portal/api/IcdCode/IcdCodeRemove/${icdCodeId}`,
    IcdCodeGetKeyPairValues: (code) =>
        `/portal/api/IcdCode/IcdCodeGetKeyPairValues/${code}`,

    // DataTypes

    DataTypesGetAll: () => `/portal/api/DataTypes/DataTypesGetAll`,
    DataTypesAdd: () => `/portal/api/DataTypes/DataTypesAdd`,
    DataTypesGetById: (dataTypeId: any) =>
        `/portal/api/DataTypes/DataTypesGetById/${dataTypeId}`,
    DataTypesRemove: (dataTypeId: any) =>
        `/portal/api/DataTypes/DataTypesRemove/${dataTypeId}`,
    DataTypesGetByName: (dataTypeName: any) =>
        `/portal/api/DataTypes/DataTypesGetByName/${dataTypeName}`,
    DataTypesGetKeyPairValues: () =>
        `/portal/api/DataTypes/DataTypesGetKeyPairValues`,

    // Field

    FieldGetAll: () => `/portal/api/Field/FieldGetAll`,
    FieldAdd: () => `/portal/api/Field/FieldAdd`,
    // FieldGet: () => `/portal/api/Field/FieldAdd`,
    FieldGetById: (fieldId: any) => `/portal/api/Field/FieldGetById/${fieldId}`,
    FieldRemove: (fieldId: any) => `/portal/api/Field/FieldRemove/${fieldId}`,
    FieldGetKeyPairValues: () => `/portal/api/Field/FieldGetKeyPairValues`,

    // Locations

    LocationsGetAll: () => `/portal/api/Locations/LocationsGetAll`,
    LocationsAdd: () => `/portal/api/Locations/LocationsAdd`,
    LocationsGetById: (locationId: any) =>
        `/portal/api/Locations/LocationsGetById/${locationId}`,
    LocationsRemove: (locationId: any) =>
        `/portal/api/Locations/LocationsRemove/${locationId}`,
    LocationsGetKeyPairValues: () =>
        `/portal/api/Locations/LocationsGetKeyPairValues`,
    LocationsGetByOrganizationId: (organizationId: any) =>
        `/portal/api/Locations/LocationsGetByOrganizationId/${organizationId}`,

    // Insurers

    InsurersGetAll: () => `/portal/api/Payer/PayerGetAll`,
    InsurersAdd: () => `/portal/api/Payer/PayerAdd`,
    InsurersGetById: (insurerId: any) =>
        `/portal/api/Payer/PayerGetById/${insurerId}`,
    InsurersRemove: (insurerId: any) =>
        `/portal/api/Payer/PayerRemove/${insurerId}`,
    InsurersGetKeyPairValues: () => `/portal/api/Payer/PayerGetKeyPairValues`,
    InsurersGetByOrganizationId: (organizationId: any) =>
        `/portal/api/Payer/PayerGetByOrganizationId/${organizationId}`,

    // FieldMappings

    FieldMappingsGetAll: () => `/portal/api/FieldMappings/FieldMappingsGetAll`,
    FieldMappingsAdd: () => `/portal/api/FieldMappings/FieldMappingsAdd`,
    FieldMappingsGetById: (fieldMappingId: any) =>
        `/portal/api/FieldMappings/FieldMappingsGetById/${fieldMappingId}`,
    FieldMappingsRemove: (fieldMappingId: any) =>
        `/portal/api/FieldMappings/FieldMappingsRemove/${fieldMappingId}`,
    FieldMappingsGetByOrganizationid: (organizationid: any) =>
        `/portal/api/FieldMappings/FieldMappingsGetByOrganizationid/${organizationid}`,
    FieldMappingsGetKeyPairValues: () =>
        `/portal/api/FieldMappings/FieldMappingsGetKeyPairValues`,

    // Settings

    SettingsGetAll: () => `/portal/api/Settings/SettingsGetAll`,
    SettingsAdd: () => `/portal/api/Settings/SettingsAdd`,
    SettingsGetById: (settingId: any) =>
        `/portal/api/Settings/SettingsGetById/${settingId}`,
    SettingsRemove: (settingId: any) =>
        `/portal/api/Settings/SettingsRemove/${settingId}`,
    SettingsGetByOrganizationId: (organizationId: any) =>
        `/portal/api/Settings/SettingsGetByOrganizationId/${organizationId}`,
    SettingsGetKeyPairValues: () =>
        `/portal/api/Settings/SettingsGetKeyPairValues`,
    SettingsGetBySettingKey: (settingKey: any) =>
        `/portal/api/Settings/SettingsGetBySettingKey/${settingKey}`,

    // UserSettings

    UserSettingsGetAll: (userId: any) =>
        `/portal/api/UserSettings/UserSettingsGetAll/${userId}`,
    UserSettingsAdd: (userId) =>
        `/portal/api/UserSettings/UserSettingsAdd/${userId}`,
    UserSettingsGetById: (settingId: any) =>
        `/portal/api/UserSettings/UserSettingsGetById/${settingId}`,
    UserSettingsRemove: (settingId: any) =>
        `/portal/api/UserSettings/UserSettingsRemove/${settingId}`,
    UserSettingsGetKeyPairValues: () =>
        `/portal/api/UserSettings/UserSettingsGetKeyPairValues`,
    UserSettingsGetBySettingKey: (settingKey: any) =>
        `/portal/api/UserSettings/UserSettingsGetBySettingKey/${settingKey}`,

    // Groups

    GroupsGetAll: () => `/portal/api/Groups/GroupsGetAll`,
    GroupsAdd: () => `/portal/api/Groups/GroupsAdd`,
    GroupsGetById: (groupId: any) =>
        `/portal/api/Groups/GroupsGetById/${groupId}`,
    GroupsRemove: (groupId: any) =>
        `/portal/api/Groups/GroupsRemove/${groupId}`,
    GroupsGetByName: (groupName: any) =>
        `/portal/api/Groups/GroupsGetByName/${groupName}`,
    GroupsGetKeyPairValues: () => `/portal/api/Groups/GroupsGetKeyPairValues`,

    // Organization

    OrganizationGetAll: () => `/portal/api/Organization/OrganizationGetAll`,
    OrganizationAdd: () => `/portal/api/Organization/OrganizationAdd`,
    OrganizationGetById: (organizationId: any) =>
        `/portal/api/Organization/OrganizationGetById/${organizationId}`,
    OrganizationRemove: (organizationId: any) =>
        `/portal/api/Organization/OrganizationRemove/${organizationId}`,
    OrganizationGetKeyPairValues: () =>
        `/portal/api/Organization/OrganizationGetKeyPairValues`,

    // User

    UserGetAll: () => `/portal/api/User/UserGetAll`,
    UserAdd: () => `/portal/api/User/UserAdd`,
    UserRolesAdd: () => `/portal/api/Role/RoleAddUserRoles`,
    UserGetById: (userId: any) => `/portal/api/User/UserGetById/${userId}`,
    UserRemove: (userId: any) => `/portal/api/User/UserRemove/${userId}`,
    UserGetCurrentOrganizationId: () =>
        `/portal/api/User/UserGetCurrentUserOrganizationId`,
    UserGetKeyPairValues: () => "/portal/api/User/UserGetKeyPairValues",
    UserGetByOrganizationId: (organizationId: any) =>
        `/portal/api/User/UserGetByOrganizationId/${organizationId}`,
    UserUpdateUser: () => "/portal/api/User/UserUpdateUser",
    GetUserRoles: (userId: any) =>
        `/portal/api/Role/RoleGetUserRoles/${userId}`,
    RemoveUserRoles: () => `/portal/api/Role/RoleRemoveUserRole`,
    GetUserClaims: (userId: any) =>
        `/portal/api/UserClaims/UserClaimsGetUserClaims/${userId}`,
    GetUserAllowedApps: (userId: any) =>
        `/portal/api/UserClaims/UserClaimsGetUserAllowedApps/${userId}`,
    AddUserClaims: () => `/portal/api/UserClaims/UserClaimsAddUserClaims`,
    AddAllowedApps: (id:any) => `/portal/api/UserClaims/UserClaimsAddAllowedApps/${id}`,
    RemoveUserClaims: () => `/portal/api/UserClaims/UserClaimsRemoveUserClaim`,

    // VisitTypes

    VisitTypesGetAll: () => `/portal/api/VisitTypes/VisitTypesGetAll`,
    VisitTypesAdd: () => `/portal/api/VisitTypes/VisitTypesAdd`,
    VisitTypesGetById: (visitTypeId: any) =>
        `/portal/api/VisitTypes/VisitTypesGetById/${visitTypeId}`,
    VisitTypesRemove: (visitTypeId: any) =>
        `/portal/api/VisitTypes/VisitTypesRemove/${visitTypeId}`,
    VisitTypesGetByName: (visitTypeName: any) =>
        `/portal/api/VisitTypes/VisitTypesGetByName/${visitTypeName}`,
    VisitTypesGetKeyPairValues: () =>
        `/portal/api/VisitTypes/VisitTypesGetKeyPairValues`,

    // CptCodeTypes

    CptCodeServiceTypeGetAll: () =>
        `/portal/api/CptCodeServiceType/CptCodeServiceTypeGetAll`,
    CptCodeServiceTypeAdd: () =>
        `/portal/api/CptCodeServiceType/CptCodeServiceTypeAdd`,
    CptCodeServiceTypeGetById: (cptCodeTypeId: number) =>
        `/portal/api/CptCodeServiceType/CptCodeServiceTypeGetById/${cptCodeTypeId}`,
    CptCodeServiceTypeRemove: (cptCodeTypeId: number) =>
        `/portal/api/CptCodeServiceType/CptCodeServiceTypeRemove/${cptCodeTypeId}`,
    CptCodeServiceTypeGetKeyPairValues: () =>
        `/portal/api/CptCodeServiceType/CptCodeServiceTypeGetKeyValuePair`,

    // CloudContainers

    CloudContainersGetAll: () =>
        `/portal/api/CloudContainer/CloudContainerGetAll`,
    CloudContainersAdd: () => `/portal/api/CloudContainer/CloudContainerAdd`,
    CloudContainersGetById: (cloudContainerId: any) =>
        `/portal/api/CloudContainer/CloudContainerGetById/${cloudContainerId}`,
    CloudContainersRemove: (cloudContainerId: any) =>
        `/portal/api/CloudContainer/CloudContainerRemove/${cloudContainerId}`,
    CloudContainersGetKeyValuePair: () =>
        `/portal/api/CloudContainer/CloudContainerGetKeyPairValue`,

    // CloudType

    CloudTypesGetAll: () => `/portal/api/CloudTypes/CloudTypesGetAll`,
    CloudTypesAdd: () => `/portal/api/CloudTypes/CloudTypesAdd`,
    CloudTypesGetById: (cloudTypesId: any) =>
        `/portal/api/CloudTypes/CloudTypesGetById/${cloudTypesId}`,
    CloudTypesRemove: (cloudTypesId: any) =>
        `/portal/api/CloudTypes/CloudTypesRemove/${cloudTypesId}`,
    CloudTypesGetKeyValuePair: () =>
        `/portal/api/CloudTypes/CloudTypesGetKeyValue`,

    // CloudUserTypes

    CloudUserTypesGetAll: () =>
        `/portal/api/CloudUserTypes/CloudUserTypesGetAll`,
    CloudUserTypesAdd: () => `/portal/api/CloudUserTypes/CloudUserTypesAdd`,
    CloudUserTypesGetById: (CloudUserTypesId: any) =>
        `/portal/api/CloudUserTypes/CloudUserTypesGetById/${CloudUserTypesId}`,
    CloudUserTypesRemove: (CloudUserTypesId: any) =>
        `/portal/api/CloudUserTypes/CloudUserTypesRemove/${CloudUserTypesId}`,
    CloudUserTypesGetKeyValuePair: () =>
        `/portal/api/CloudUserTypes/CloudUserTypesGetKeyValue`,

    // CloudUsers

    CloudUserGetAll: () => `/portal/api/CloudUser/CloudUserGetAll`,
    CloudUserAdd: () => `/portal/api/CloudUser/CloudUserAdd`,
    CloudUserGetById: (CloudUserId: any) =>
        `/portal/api/CloudUser/CloudUserGetById/${CloudUserId}`,
    CloudUserRemove: (CloudUserId: any) =>
        `/portal/api/CloudUser/CloudUserRemove/${CloudUserId}`,
    CloudUserGetKeyValuePair: () =>
        `/portal/api/CloudUser/CloudUserGetKeyValue`,

    // Content Storage Container type

    ContainerTypeGetAll: () =>
        `/portal/api/ContentStorageContainerType/ContentStorageContainerTypeGetAll`,
    ContainerTypeAdd: () =>
        `/portal/api/ContentStorageContainerType/ContentStorageContainerTypeAdd`,
    ContainerTypeGetById: (containerTypeId: any) =>
        `/portal/api/ContentStorageContainerType/ContentStorageContainerTypeGetById/${containerTypeId}`,
    ContainerTypeRemove: (containerTypeId: any) =>
        `/portal/api/ContentStorageContainerType/ContentStorageContainerTypeRemoveById/${containerTypeId}`,
    ContainerTypeGetKeyValuePair: () =>
        `/portal/api/ContentStorageContainerType/ContentStorageContainerTypeGetKeyValuePair`,

    // Content Storage Container

    ContainerGetAll: () =>
        `/portal/api/ContentStorageContainer/ContentStorageContainerGetAll`,
    ContainerAdd: () =>
        `/portal/api/ContentStorageContainer/ContentStorageContainerAdd`,
    ContainerGetById: (containerId: any) =>
        `/portal/api/ContentStorageContainer/ContentStorageContainerGetById/${containerId}`,
    ContainerRemove: (containerId: any) =>
        `/portal/api/ContentStorageContainer/ContentStorageContainerRemoveById/${containerId}`,
    ContainerGetKeyValuePair: () =>
        `/portal/api/ContentStorageContainer/ContentStorageContainerGetKeyValue`,

    // Content Storage Folder

    FolderGetAll: () =>
        `/portal/api/ContentStorageFolder/ContentStorageFolderGetAll`,
    FolderAdd: () =>
        `/portal/api/ContentStorageFolder/ContentStorageFolderAdd`,
    FolderGetById: (folderId: any) =>
        `/portal/api/ContentStorageFolder/ContentStorageFolderGetById/${folderId}`,
    FolderRemove: (folderId: any) =>
        `/portal/api/ContentStorageFolder/ContentStorageFolderRemoveById/${folderId}`,
    FolderGetKeyValuePair: () =>
        `/portal/api/ContentStorageFolder/ContentStorageFolderGetKeyValuePair`,

    // DiagnosisCode

    DiagnosisCodeGetAll: () => `/portal/api/DiagnosisCode/DiagnosisCodeGetAll`,
    DiagnosisCodeAdd: () => `/portal/api/DiagnosisCode/DiagnosisCodeAdd`,
    DiagnosisCodeCount: (code) =>
        `/portal/api/DiagnosisCode/DiagnosisCodeGetDiagnosisCount?code=${code}`,
    DiagnosisCodeGetById: (diagnosisCodeId: any) =>
        `/portal/api/DiagnosisCode/DiagnosisCodeGetById/${diagnosisCodeId}`,
    DiagnosisCodeRemove: (diagnosisCodeId: any) =>
        `/portal/api/DiagnosisCode/DiagnosisCodeRemove/${diagnosisCodeId}`,
    DiagnosisCodeGetKeyValuePair: () =>
        `/portal/api/DiagnosisCode/DiagnosisCodeGetKeyPairValue`,

    // File Type

    FileTypeGetAll: () => `/portal/api/FileType/FileTypeGetAll`,
    FileTypeAdd: () => `/portal/api/FileType/FileTypeAdd`,
    FileTypeCount: (code) =>
        `/portal/api/FileType/FileTypeGetDiagnosisCount?code=${code}`,
    FileTypeGetById: (FileTypeId: any) =>
        `/portal/api/FileType/FileTypeGetById/${FileTypeId}`,
    FileTypeRemove: (FileTypeId: any) =>
        `/portal/api/FileType/FileTypeRemoveById/${FileTypeId}`,
    FileTypeGetKeyValuePair: () =>
        `/portal/api/FileType/FileTypeGetKeyPairValue`,

    // Forms apis
    FormsGetAll: () => `/portal/api/Forms/FormsGetAll`,
    FormsGetById: (id: any) => `/portal/api/Forms/FormsGetById/${id}`,
    FormsAdd: () => `/portal/api/Forms/FormsAdd`,
    FormsRemove: (id: any) => `/portal/api/Forms/FormsRemove/${id}`,
    FormsKeyPairValue: () => `/portal/api/Forms/FormsGetFormKeyPairValue`,
    FormsSpecialitiesKey: (id: any) =>
        `/portal/api/Forms/FormsGetFormSpecialtiesKey/${id}`,

    // Specialties apis

    SpecialtiesGetAll: () => `/portal/api/Specialties/SpecialtiesGetAll`,
    SpecialtiesAdd: () => `/portal/api/Specialties/SpecialtiesAdd`,
    SpecialtiesKeyValuePair: () =>
        `/portal/api/Specialties/SpecialtiesGetKeyValuePair`,
    SpecialtiesGetById: (id: any) =>
        `/portal/api/Specialties/SpecialtiesGetById/${id}`,
    SpecialtiesRemove: (id: any) =>
        `/portal/api/Specialties/SpecialtiesRemove/${id}`,

    // Form Group

    FormGroupGetAll: (id) => `/portal/api/FormGroups/FormGroupsGetAll/${id}`,
    FormGroupAdd: () => `/portal/api/FormGroups/FormGroupsAdd`,
    FormGroupIndexUpdate: () => `/portal/api/FormGroups/FormGroupsIndexUpdate`,
    FormGroupGetById: (id: any) =>
        `/portal/api/FormGroups/FormGroupsGetById/${id}`,
    FormGroupRemove: (id: any) =>
        `/portal/api/FormGroups/FormGroupsRemove/${id}`,

    // FormGroupSetting

    FormGroupSettingsGetAll: (formGroupId) =>
        `/portal/api/FormGroupSettings/FormGroupSettingsGetAll/${formGroupId}`,
    FormGroupSettingsUpdate: (formGroupId: any) =>
        `/portal/api/FormGroupSettings/FormGroupSettingsUpdate/${formGroupId}`,

    // Form fields

    FormGroupFieldsGetAll: (formGroupId: any) =>
        `/portal/api/FormField/FormFieldGetAll/${formGroupId}`,
    FormGroupFieldsGetByFormId: (formId: any) =>
        `/portal/api/FormField/FormFieldGetAllFormFileds/${formId}`,
    FormGroupFieldsAdd: () => `/portal/api/FormField/FormFieldAdd`,
    FormGroupFieldsIndexUpdate: () =>
        `/portal/api/FormField/FormFieldUpdateIndex`,
    FormGroupFieldsGetById: (id: any) =>
        `/portal/api/FormField/FormFieldGetById/${id}`,
    SubFormFieldsGetByFieldId: (id: any) =>
        `/portal/api/FormField/FormFieldGetSubFormFields/${id}`,
    FormGroupFieldsRemove: (id: any) =>
        `/portal/api/FormField/FormFieldRemove/${id}`,
    FormGroupFieldsKeyValue: (id: any) =>
        `/portal/api/FormField/FormFieldGetKeyValuePair/${id}`,

    // field type
    FieldTypeGetAll: () => `/portal/api/FieldType/FieldTypeGetAll`,
    FieldTypeAdd: () => `/portal/api/FieldType/FieldTypeAdd`,
    FieldTypeGetById: (id: any) =>
        `/portal/api/FieldType/FieldTypeGetById/${id}`,
    FieldTypeRemove: (id: any) => `/portal/api/FieldType/FieldTypeRemove/${id}`,
    FieldTypeKeyValue: () => `/portal/api/FieldType/FieldTypeGetKeyPairValues`,
    FieldTypeGetByName: (fieldTypeName: any) =>
        `/portal/api/FieldType/FieldTypeGetByName/${fieldTypeName}`,

    // FormFieldsSettings

    FormFieldSettingsGetAll: (FormFieldId: any) =>
        `/portal/api/FormFieldSetting/FormFieldSettingGetAll/${FormFieldId}`,
    FormFieldSettingsUpdate: (formFieldId: any) =>
        `/portal/api/FormFieldSetting/FormFieldSettingUpdate/${formFieldId}`,

    // user Info api
    userInfoGet: () => `/connect/userinfo`,

    // Global Settings Api

    GlobalSettingGetAll: () => `/portal/api/GlobalSetting/GlobalSettingGetAll`,
    GlobalSettingGetBySettingKey: (settingKey: any) =>
        `/portal/api/GlobalSetting/GlobalSettingGetBySettingKey/${settingKey}`,
    GlobalSettingUpdate: () =>
        `/portal/api/GlobalSetting/GlobalSettingUpdateSettings`,

    // settings api urls

    GeneralSettingsGet: () => `/portal/api/Settings/SettingsGetGeneralSettings`,
    SmtpSettingsGet: () => `/portal/api/Settings/SettingsGetSmtpSettings`,
    EmrInboundSettingsGet: () =>
        `/portal/api/Settings/SettingsGetEmrInboundSetting`,
    EmrOutboundSettingsGet: () =>
        `/portal/api/Settings/SettingsGetEmrOutboundSetting`,
    SettingsGetByKey: (settingKey: any) =>
        `/portal/api/Settings/SettingsGetBySettingKey/${settingKey}`,
    SettingsUpdateSettings: () => `/portal/api/Settings/SettingsUpdateSettings`,
    SettingsGetHostValue: (channelType: any) =>
        `/portal/api/Settings/SettingsGetHostAndPortValue/${channelType}`,
    SettingsUpdateInboundHostandPort: () =>
        `/portal/api/Settings/SettingsUpdateInboundHostAndPort`,

    ServiceItemGetAll: () => `/portal/api/ServiceItems/ServiceItemsGetAll`,
    ServiceItemRecordCount: (code) =>
        `/portal/api/ServiceItems/ServiceItemsGetServiceItemCount?code=${code}`,
    ServiceItemAdd: () => `/portal/api/ServiceItems/ServiceItemsAdd`,
    ServiceItemGetKeyValue: (code) =>
        `/portal/api/ServiceItems/ServiceItemsGetKeyValuePair/code?code=${code}`,
    ServiceItemGetAllKeyValue: (code) =>
        `/portal/api/ServiceItems/ServiceItemsGetAllServiceItem?code=${code}`,
    ServiceItemGetById: (id: any) =>
        `/portal/api/ServiceItems/ServiceItemsGetById/${id}`,
    ServiceItemRemove: (id: any) =>
        `/portal/api/ServiceItems/ServiceItemsRemove/${id}`,

    // Reset password

    PasswordResetUrl: () => `/portal/api/User/UserResetPassword`,

    // Provider URls;

    ProviderGetAll: () => `/portal/api/Provider/ProviderGetAll`,
    ProviderGetById: (providerId: any) =>
        `/portal/api/Provider/ProviderGetById/${providerId}`,
    ProviderAdd: () => `/portal/api/Provider/ProviderAdd`,
    ProviderRemove: (providerId: any) =>
        `/portal/api/Provider/ProviderRemove/${providerId}`,
    ProviderKeyValue: () => `/portal/api/Provider/ProviderGetKeyValuePair`,

    // Roles get
    UserGetAllRoles: () => `/portal/api/Role/RoleGetAllRoles`,
    UserGetRolesByUserId: (userId: any) =>
        `/portal/api/Role/RoleGetUserRoles/${userId}`,
    CurrentUserRole: () => `/portal/api/Role/RoleGetCurrentUserRole`,

    // visit type

    VisitTypeGetAll: () => `/portal/api/VisitTypes/VisitTypesGetAll`,
    VisitTypeAdd: () => `/portal/api/VisitTypes/VisitTypesAdd`,
    VisitTypeGetById: (visitTypeId: any) =>
        `/portal/api/VisitTypes/VisitTypesGetById/${visitTypeId}`,
    VisitTypeRemove: (visitTypeId: any) =>
        `/portal/api/VisitTypes/VisitTypesRemove/${visitTypeId}`,
    VisitTypeGetByName: (visitTypeName: any) =>
        `/portal/api/VisitTypes/VisitTypesGetAll`,
    VisitTypeGetKeyPairValue: () => `/portal/api/VisitTypes/VisitTypesGetAll`,

    // OrganizationForms
    OrganizationFormGetAll: () =>
        `/portal/api/OrganizationForms/OrganizationFormsGetAll`,
    OrganizationFormAdd: () =>
        `/portal/api/OrganizationForms/OrganizationFormsAdd`,
    OrganizationFormDelete: (organizationFormId: number) =>
        `/portal/api/OrganizationForms/OrganizationFormsRemove/${organizationFormId}`,

    // Field Group COnditions

    FormGroupConditionDataTypeGetAll: () =>
        `/portal/api/FormGroupCondition/FormGroupConditionDataTypeOperatorGetAll`,
    FormGroupConditionFieldGetAll: () =>
        `/portal/api/FormGroupCondition/FormGroupConditionGetFormGroupConditionFields`,
    FormGroupConditionGroupGetAll: (formId: any, formGroupId: any) =>
        `/portal/api/FormGroupCondition/FormGroupConditionGetAllFormGroupConditionGroup/${formId}/${formGroupId}`,
    FormGroupConditionAdd: () =>
        `/portal/api/FormGroupCondition/FormGroupConditionAddFormGroupConditionGroup`,

    //Field Conditions apis

    FieldConditionGetPatientField: () =>
        `/portal/api/FormFieldCondition/FormFieldConditionGetPatientFormFields`,
    FieldConditionGetAllConditionGroup: (formId, formGroupId, formFieldId) =>
        `/portal/api/FormFieldCondition/FormFieldConditionGetFormFieldConditionGroups/${formId}/${formGroupId}/${formFieldId}`,
    FieldConditionAddConditionGroup: () =>
        `/portal/api/FormFieldCondition/FormFieldConditionAddFormFieldConditionGroups`,

    FieldDependencyConditionGetAll: (formId, formGroupId, formFieldId) =>
        `/portal/api/FormFieldDependencyCondition/FormFieldDependencyConditionGetAllFormFieldDependencyConditionGroups/${formId}/${formGroupId}/${formFieldId}`,
    FieldDependencyConditionAdd: () =>
        `/portal/api/FormFieldDependencyCondition/FormFieldDependencyConditionAddFormFieldDependencyConditionGroups`,
    FieldDependencyConditionGetFieldProperties: () =>
        `/portal/api/FormFieldDependencyCondition/FormFieldDependencyConditionGetAllFieldProperties`,

    //Field values

    GetFieldValue: (formFieldId) =>
        `/portal/api/FieldValue/FieldValueGetAll/${formFieldId}`,

    GetFieldValueById: (fieldValueId) =>
        `/portal/api/FieldValue/FieldValueGetById/${fieldValueId}`,
    AddFieldValue: () => `/portal/api/FieldValue/FieldValueAdd`,
    RemoveFieldValue: (formFieldId) =>
        `/portal/api/FieldValue/FieldValueRemove/${formFieldId}`,
    UpdateFieldValueIndex: () => `/portal/api/FieldValue/FieldValueUpdateIndex`,
};
