import { Component, Input, OnInit } from '@angular/core';
import { NavigationService } from '../../../services/navigation.service';
import { LayoutService } from '../../../services/layout.service';
import { ConfigService } from '../../../config/config.service';
import { map, startWith, switchMap } from 'rxjs/operators';
import { NavigationLink } from '../../../interfaces/navigation-item.interface';
import { PopoverService } from '../../components/popover/popover.service';
import { Observable, of } from 'rxjs';
import { UserMenuComponent } from '../../components/user-menu/user-menu.component';
import { MatDialog } from '@angular/material/dialog';
import { SearchModalComponent } from '../../components/search-modal/search-modal.component';
import { OAuthService } from 'angular-oauth2-oidc';
import { CommonService } from 'src/app/shared/services/api-services/common.service';
import { MainService } from 'src/app/shared/services/main.service';
import { AdminServiceService } from 'src/app/shared/services/admin-service.service';

@Component({
    selector: "vex-sidenav",
    templateUrl: "./sidenav.component.html",
    styleUrls: ["./sidenav.component.scss"],
})
export class SidenavComponent implements OnInit {
    @Input() collapsed: boolean;
    userInfo: any = {};
    collapsedOpen$ = this.layoutService.sidenavCollapsedOpen$;
    title$ = this.configService.config$.pipe(
        map((config) => config.sidenav.title)
    );
    imageUrl$ = this.configService.config$.pipe(
        map((config) => config.sidenav.imageUrl)
    );
    showCollapsePin$ = this.configService.config$.pipe(
        map((config) => config.sidenav.showCollapsePin)
    );
    userVisible$ = this.configService.config$.pipe(
        map((config) => config.sidenav.user.visible)
    );
    searchVisible$ = this.configService.config$.pipe(
        map((config) => config.sidenav.search.visible)
    );

    userMenuOpen$: Observable<boolean> = of(false);

    items = this.navigationService.items;

    constructor(
        private navigationService: NavigationService,
        private layoutService: LayoutService,
        private configService: ConfigService,
        private readonly popoverService: PopoverService,
        private readonly dialog: MatDialog,
        private oauthService: OAuthService,
        private commonService: CommonService,
        private mainService: MainService,
        private adminService: AdminServiceService
    ) {
        this.adminService.isNavigationChange$.subscribe((res) => {
            if (res) {
                this.items = this.navigationService.items;
            }
        });
    }

    ngOnInit() {
        this.getUserInfo();
    }

    collapseOpenSidenav() {
        this.layoutService.collapseOpenSidenav();
    }

    collapseCloseSidenav() {
        this.layoutService.collapseCloseSidenav();
    }

    toggleCollapse() {
        this.collapsed
            ? this.layoutService.expandSidenav()
            : this.layoutService.collapseSidenav();
    }

    trackByRoute(index: number, item: NavigationLink): string {
        return item.route;
    }

    openProfileMenu(origin: HTMLDivElement): void {
        this.userMenuOpen$ = of(
            this.popoverService.open({
                content: UserMenuComponent,
                origin,
                offsetY: -8,
                width: origin.clientWidth,
                position: [
                    {
                        originX: "center",
                        originY: "top",
                        overlayX: "center",
                        overlayY: "bottom",
                    },
                ],
            })
        ).pipe(
            switchMap((popoverRef) =>
                popoverRef.afterClosed$.pipe(map(() => false))
            ),
            startWith(true)
        );
    }

    openSearch(): void {
        this.dialog.open(SearchModalComponent, {
            panelClass: "vex-dialog-glossy",
            width: "100%",
            maxWidth: "600px",
        });
    }

    getUserInfo() {
        this.commonService.getUserInfo().subscribe((res) => {
            if (res) {
                this.userInfo = res;
                if (
                    this.oauthService.hasValidAccessToken() &&
                    this.oauthService.hasValidIdToken()
                ) {
                    this.mainService.checkUser.next("true");
                }
            }
        });
    }

    logout() {
        this.oauthService.customQueryParams={
            "id_token_hint":this.oauthService.getIdToken()
        }
        this.oauthService.logOut();
        sessionStorage.clear();
    }
}
