import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
export interface SpinnerState {
    show: boolean;
}

@Injectable({
  providedIn: "root",
})
export class SpinnerService {
  private loaderSubject = new Subject<SpinnerState>();
  public counter = 0;

  spinnerState = this.loaderSubject.asObservable();

  constructor() {
    this.counter = 0;
  }

  show() {
    this.loaderSubject.next(<SpinnerState>{ show: true });
  }

  hide() {
    this.loaderSubject.next(<SpinnerState>{ show: false });
  }
}