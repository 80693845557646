import { Injectable } from "@angular/core";
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from "@angular/common/http";
import { Observable, switchMap } from "rxjs";
import { AdminServiceService } from "../services/admin-service.service";

@Injectable()
export class HeaderInterceptInterceptor implements HttpInterceptor {
    constructor(private adminService: AdminServiceService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): any {
        
        let orgId = (sessionStorage.getItem("orgId"))?JSON.parse(sessionStorage.getItem("orgId")).toString():'0';
        // let requestCopy = request.clone();
        if (orgId != "0") {
            // let orgId = res.toString();
            const requestCopy = request.clone({
                setHeaders: { Organizationid: orgId },
            });
            return next.handle(requestCopy);
        } else {
            return next.handle(request);
        }
        // return this.adminService.orgId$.pipe(
        //     switchMap((res) => {
        //     })
        // );
        // return next.handle(requestCopy);
    }
}
