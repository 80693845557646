import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree,
} from "@angular/router";
import { OAuthService } from "angular-oauth2-oidc";
import { resolve } from "path";
import { Observable } from "rxjs";
import { Location } from "@angular/common";
@Injectable({
    providedIn: "root",
})
export class AuthGuard implements CanActivate, CanLoad {
    constructor(
        private router: Router,
        private oauthService: OAuthService,
        private location: Location
    ) {}
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.checkLogin();
    }
    canLoad(
        route: Route,
        segments: UrlSegment[]
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.checkLogin();
    }

    checkLogin(): any {
        // if (this.oauthService.getIdentityClaims() !== null) {
        //     if (
        //         this.oauthService.getAccessToken() &&
        //         this.oauthService.hasValidAccessToken()
        //     ) {
        //         let timeVar = setTimeout(() => {
        //             this.router.navigate(["/main/dashboard"]);
        //         }, 500);
        //         // clearTimeout(timeVar);
        //         return false;
        //     } else {
        //         // this.router.navigate(['/auth/login']);
        //         return true;
        //     }
        // }
        // debugger;
        if (this.oauthService.getIdentityClaims() !== null) {
            let currentTime = new Date();
            let futureTime = this.oauthService.getAccessTokenExpiration();
            let timeDifference = futureTime
                ? futureTime - currentTime.getTime()
                : 0;
            if (timeDifference > 0) {
                this.router.navigate(["/main/dashboard"]);
                return false;
            } else {
                // this.router.navigate(["/auth/login"]);
                return true;
            }
        } else {
            return true;
        }
    }
}
