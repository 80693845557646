import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { ApiUrls } from 'src/app/config/api-url-config';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: "root",
})
export class OrganizationCrudService {
    constructor(private httpClient: HttpClient) {}

    OrganizationGetAllApi(): Observable<any> {
        return this.httpClient
            .get(environment.apiUrlBase() + ApiUrls.OrganizationGetAll())
            .pipe(
                map((body: any) => body),
                catchError((error: any) =>
                    throwError(error || "Error, while loading data ")
                )
            );
    }
    OrganizationAddApi(request): Observable<any> {
        return this.httpClient
            .post(environment.apiUrlBase() + ApiUrls.OrganizationAdd(), request)
            .pipe(
                map((body: any) => body),
                catchError((error: any) =>
                    throwError(error || "Error, while loading data ")
                )
            );
    }
    OrganizationGetByIdApi(orgId:any): Observable<any> {
        return this.httpClient
            .get(environment.apiUrlBase() + ApiUrls.OrganizationGetById(orgId))
            .pipe(
                map((body: any) => body),
                catchError((error: any) =>
                    throwError(error || "Error, while loading data ")
                )
            );
    }
    OrganizationrRemoveApi(orgId:any): Observable<any> {
        return this.httpClient
            .delete(environment.apiUrlBase() + ApiUrls.OrganizationRemove(orgId))
            .pipe(
                map((body: any) => body),
                catchError((error: any) =>
                    throwError(error || "Error, while loading data ")
                )
            );
    }
    OrganizationGetKeyValueApi(): Observable<any> {
        return this.httpClient
            .get(environment.apiUrlBase() + ApiUrls.OrganizationGetKeyPairValues())
            .pipe(
                map((body: any) => body),
                catchError((error: any) =>
                    throwError(error || "Error, while loading data ")
                )
            );
    }
}
