import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
export interface CurrentUserRoles{
    Roles:string[],
}
@Injectable({
    providedIn: "root",
})
export class AdminServiceService {
    isAdminSubject = new BehaviorSubject<boolean>(false);
    isAdmin$ = this.isAdminSubject.asObservable();
    isNavigationChangeSubject = new BehaviorSubject<boolean>(true);
    isNavigationChange$ = this.isNavigationChangeSubject.asObservable();
    organizationIdSubject = new BehaviorSubject<any>("0");
    orgId$ = this.organizationIdSubject.asObservable();
    currentUserRoleSubject = new BehaviorSubject<CurrentUserRoles>({Roles:[]});
    currentUser$ = this.currentUserRoleSubject.asObservable();
    organizationOptions=new BehaviorSubject<any>([]);
    constructor() {}
}
