import { Component, Inject, LOCALE_ID, Renderer2 } from "@angular/core";
import { Settings } from "luxon";
import { DOCUMENT } from "@angular/common";
import { Platform } from "@angular/cdk/platform";
import { ActivatedRoute } from "@angular/router";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import {
    MatIconRegistry,
    SafeResourceUrlWithIconOptions,
} from "@angular/material/icon";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ConfigService } from "./shared/config/config.service";
import { LayoutService } from "./shared/services/layout.service";
import { NavigationService } from "./shared/services/navigation.service";
import { SplashScreenService } from "./shared/services/splash-screen.service";
import { VexConfigName } from "./shared/config/config-name.model";
import { ColorSchemeName } from "./shared/config/colorSchemeName";
import {
    ColorVariable,
    colorVariables,
} from "./shared/theme/components/config-panel/color-variables";
import { OAuthService } from "angular-oauth2-oidc";
import { MainService } from "./shared/services/main.service";
import { authConfigData } from "./config/auth.config";
import { Router } from "@angular/router";
import { sweetAlertWarning } from "./shared/functions/sweet-alert-fire";
import { roles } from "./shared/functions/roles";
import { UsersService } from "./shared/services/api-services/users.service";
import { AdminServiceService } from "./shared/services/admin-service.service";
import { SpinnerService } from "./shared/services/spinner.service";
@Component({
    selector: "vex-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent {
    timeOutClear: any;
    tokenTimeOut: any;
    currentRoles: any;
    menuItems: any[];
    constructor(
        private userService: UsersService,
        private configService: ConfigService,
        private renderer: Renderer2,
        private platform: Platform,
        @Inject(DOCUMENT) private document: Document,
        @Inject(LOCALE_ID) private localeId: string,
        private route: ActivatedRoute,
        private navigationService: NavigationService,
        private splashScreenService: SplashScreenService,
        private readonly matIconRegistry: MatIconRegistry,
        private readonly domSanitizer: DomSanitizer,
        private oauthService: OAuthService,
        private mainService: MainService,
        private router: Router,
        private adminService: AdminServiceService,
    ) {
        Settings.defaultLocale = this.localeId;

        if (this.platform.BLINK) {
            this.renderer.addClass(this.document.body, "is-blink");
        }

        this.matIconRegistry.addSvgIconResolver(
            (
                name: string,
                namespace: string
            ): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
                switch (namespace) {
                    case "mat":
                        return this.domSanitizer.bypassSecurityTrustResourceUrl(
                            `assets/img/icons/material-design-icons/two-tone/${name}.svg`
                        );

                    case "logo":
                        return this.domSanitizer.bypassSecurityTrustResourceUrl(
                            `assets/img/icons/logos/${name}.svg`
                        );

                    case "flag":
                        return this.domSanitizer.bypassSecurityTrustResourceUrl(
                            `assets/img/icons/flags/${name}.svg`
                        );
                }
            }
        );

        // Customize the template to your needs with the ConfigService
        // Example:
        this.configService.updateConfig({
            sidenav: {
                title: "medmio",
                imageUrl: "../assets/img/logo/no_text_version.png",
                showCollapsePin: true,
            },
            footer: {
                visible: false,
            },
        });

        /**
         * Config Related Subscriptions
         * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
         * Example: example.com/?layout=apollo&style=default
         */
        this.route.queryParamMap.subscribe((queryParamMap) => {
            if (queryParamMap.has("layout")) {
                this.configService.setConfig(
                    queryParamMap.get("layout") as VexConfigName
                );
            }

            if (queryParamMap.has("style")) {
                this.configService.updateConfig({
                    style: {
                        colorScheme: queryParamMap.get(
                            "style"
                        ) as ColorSchemeName,
                    },
                });
            }

            if (queryParamMap.has("primaryColor")) {
                const color: ColorVariable =
                    colorVariables[queryParamMap.get("primaryColor")];

                if (color) {
                    this.configService.updateConfig({
                        style: {
                            colors: {
                                primary: color,
                            },
                        },
                    });
                }
            }

            if (queryParamMap.has("rtl")) {
                this.configService.updateConfig({
                    direction: coerceBooleanProperty(queryParamMap.get("rtl"))
                        ? "rtl"
                        : "ltr",
                });
            }
        });

        /**
         * Add your own routes here
         */
    }

    ngOnInit() {
        if (this.oauthService.getAccessTokenExpiration() === null) {
            this.mainService.checkUser.subscribe((res) => {
                if (res === "true") {
                    // this.getCurrentUserRole();
                    this.tokenExpirationCheck();
                }
            });
        } else {
            this.tokenExpirationCheck();
            this.getCurrentUserRole();
        }
        this.configureAuthService();
        // this.getCurrentUserRole();
        this.oauthService.events.subscribe((event) => {
            // console.log(event)
            if (event.type == "token_received") {
                this.getCurrentUserRole();
            }
        });
    }

    getCurrentUserRole() {
        // debugger
        // this.spinner.show();
        this.userService.GetCurrentUserRole().subscribe(
            (res) => {
                if (!res.IsError) {
                    this.currentRoles = res.Result;
                    let triggerData = {
                        Roles: this.currentRoles,
                    };
                    this.adminService.currentUserRoleSubject.next(triggerData);
                    // this.spinner.hide();
                    this.navigationService.items = [
                        {
                            type: "subheading",
                            label: " ",
                            visibility:
                                this.isInRole(roles.manager()) ||
                                this.isInRole(roles.admin()),
                            children: [
                                {
                                    type: "link",
                                    label: "Dashboard",
                                    route: "/main/dashboard",
                                    icon: "mat:insights",
                                    visibility:
                                        this.isInRole(roles.manager()) ||
                                        this.isInRole(roles.admin()),
                                    // roles: [roles.admin(), roles.manager()],
                                    routerLinkActiveOptions: {
                                        exact: true,
                                    },
                                },

                                {
                                    type: "link",
                                    label: "Users",
                                    icon: "mat:group",
                                    visibility:
                                        this.isInRole(roles.manager()) ||
                                        this.isInRole(roles.admin()),
                                    route: "/main/organization/users/users-grid",
                                    routerLinkActiveOptions: {
                                        exact: true,
                                    },
                                },
                                {
                                    type: "link",
                                    label: "Provider",
                                    icon: "mat:medical_services",
                                    visibility:
                                        this.isInRole(roles.manager()) ||
                                        this.isInRole(roles.admin()),
                                    route: "/main/organization/provider/provider-list",
                                    routerLinkActiveOptions: {
                                        exact: true,
                                    },
                                },
                                {
                                    type: "link",
                                    label: "Locations",

                                    icon: "mat:location_on",
                                    visibility:
                                        this.isInRole(roles.manager()) ||
                                        this.isInRole(roles.admin()),
                                    route: "/main/organization/locations/location-grid",
                                    routerLinkActiveOptions: {
                                        exact: true,
                                    },
                                },
                                {
                                    type: "link",
                                    label: "Payers",
                                    visibility:
                                        this.isInRole(roles.manager()) ||
                                        this.isInRole(roles.admin()),
                                    icon: "mat:medical_information",
                                    route: "/main/organization/payer/payer-grid",
                                    routerLinkActiveOptions: {
                                        exact: true,
                                    },
                                },
                                {
                                    type: "link",
                                    label: "Field Mappings",
                                    visibility:
                                        this.isInRole(roles.manager()) ||
                                        this.isInRole(roles.admin()),
                                    icon: "mat:settings_input_composite",
                                    route: "/main/organization/field-mappings/field-mappings-grid",
                                    routerLinkActiveOptions: {
                                        exact: true,
                                    },
                                },
                                {
                                    type: "link",
                                    label: "Diagnosis Codes",
                                    icon: "mat:password",
                                    visibility:
                                        this.isInRole(roles.manager()) ||
                                        this.isInRole(roles.admin()),
                                    route: "/main/organization/diagnosis-codes/diagnosis-codes-grid",
                                    routerLinkActiveOptions: {
                                        exact: true,
                                    },
                                },
                                {
                                    type: "link",
                                    label: "Service Items",
                                    icon: "mat:medication",
                                    visibility:
                                        this.isInRole(roles.manager()) ||
                                        this.isInRole(roles.admin()),
                                    route: "/main/organization/service-items/service-items-grid",
                                    routerLinkActiveOptions: {
                                        exact: true,
                                    },
                                },
                                {
                                    type: "link",
                                    label: "Visit Types",
                                    icon: "mat:person_pin_circle",
                                    visibility:
                                        this.isInRole(roles.manager()) ||
                                        this.isInRole(roles.admin()),
                                    route: "/main/organization/visit/visit-type-grid",
                                    routerLinkActiveOptions: {
                                        exact: true,
                                    },
                                },
                                {
                                    type: "link",
                                    label: "Organization Forms",
                                    icon: "mat:list_alt",
                                    visibility:
                                        this.isInRole(roles.manager()) ||
                                        this.isInRole(roles.admin()),
                                    route: "/main/organization/organization-form/organization-form-grid",
                                    routerLinkActiveOptions: {
                                        exact: true,
                                    },
                                },
                                {
                                    type: "link",
                                    label: "Settings",
                                    icon: "mat:settings",
                                    visibility:
                                        this.isInRole(roles.manager()) ||
                                        this.isInRole(roles.admin()),
                                    route: "/main/organization/settings/settings",
                                    routerLinkActiveOptions: {
                                        exact: true,
                                    },
                                },
                                {
                                    type: "dropdown",
                                    label: "Admin",
                                    visibility: this.isInRole(roles.admin()),
                                    icon: "mat:admin_panel_settings",
                                    children: [
                                        {
                                            type: "link",
                                            label: "Organization",
                                            visibility: this.isInRole(
                                                roles.admin()
                                            ),
                                            route: "/main/org/organization-list",
                                            icon: "mat:corporate_fare",
                                            routerLinkActiveOptions: {
                                                exact: true,
                                            },
                                        },
                                        {
                                            type: "dropdown",
                                            label: "Cloud",
                                            icon: "mat:cloud_circle",
                                            visibility: this.isInRole(
                                                roles.admin()
                                            ),
                                            // route: "/main/cloud-container/cloud-container-grid",
                                            // routerLinkActiveOptions: {
                                            //     exact: true,
                                            // },
                                            children: [
                                                {
                                                    type: "link",
                                                    label: "Type",
                                                    icon: "mat:cloud_circle",
                                                    visibility: this.isInRole(
                                                        roles.admin()
                                                    ),
                                                    route: "/main/cloud/cloud-type",
                                                    routerLinkActiveOptions: {
                                                        exact: true,
                                                    },
                                                },
                                                {
                                                    type: "link",
                                                    label: "User Type",
                                                    icon: "mat:cloud_circle",
                                                    visibility: this.isInRole(
                                                        roles.admin()
                                                    ),
                                                    route: "/main/cloud/cloud-user-type",
                                                    routerLinkActiveOptions: {
                                                        exact: true,
                                                    },
                                                },
                                                {
                                                    type: "link",
                                                    label: "User",
                                                    icon: "mat:cloud_circle",
                                                    visibility: this.isInRole(
                                                        roles.admin()
                                                    ),
                                                    route: "/main/cloud/cloud-user",
                                                    routerLinkActiveOptions: {
                                                        exact: true,
                                                    },
                                                },
                                            ],
                                        },
                                        {
                                            type: "dropdown",
                                            label: "Content Storage",
                                            icon: "mat:cloud_circle",
                                            visibility: this.isInRole(
                                                roles.admin()
                                            ),
                                            children: [
                                                {
                                                    type: "link",
                                                    label: "Container Type",
                                                    visibility: this.isInRole(
                                                        roles.admin()
                                                    ),
                                                    route: "/main/content-storage/container-type",
                                                    routerLinkActiveOptions: {
                                                        exact: true,
                                                    },
                                                },
                                                {
                                                    type: "link",
                                                    label: "Container",
                                                    visibility: this.isInRole(
                                                        roles.admin()
                                                    ),
                                                    route: "/main/content-storage/container",
                                                    routerLinkActiveOptions: {
                                                        exact: true,
                                                    },
                                                },
                                                {
                                                    type: "link",
                                                    label: "Folder",
                                                    visibility: this.isInRole(
                                                        roles.admin()
                                                    ),
                                                    route: "/main/content-storage/folder",
                                                    routerLinkActiveOptions: {
                                                        exact: true,
                                                    },
                                                },
                                            ],
                                        },
                                        {
                                            type: "link",
                                            label: "File Type",
                                            visibility: this.isInRole(
                                                roles.admin()
                                            ),
                                            route: "/main/file/types",
                                            routerLinkActiveOptions: {
                                                exact: true,
                                            },
                                        },
                                        {
                                            type: "link",
                                            label: "Specialties",
                                            visibility: this.isInRole(
                                                roles.admin()
                                            ),
                                            route: "/main/specialties/specialties-grid",
                                            icon: "mat:corporate_fare",
                                            routerLinkActiveOptions: {
                                                exact: true,
                                            },
                                        },
                                        {
                                            type: "link",
                                            label: "Languages",
                                            visibility: this.isInRole(
                                                roles.admin()
                                            ),
                                            route: "/main/languages/language-grid",
                                            icon: "mat:language",
                                            routerLinkActiveOptions: {
                                                exact: true,
                                            },
                                        },
                                        {
                                            type: "link",
                                            label: "Emr Types",
                                            visibility: this.isInRole(
                                                roles.admin()
                                            ),
                                            icon: "mat:source",
                                            route: "/main/emr-types/emr-types-grid",
                                            routerLinkActiveOptions: {
                                                exact: true,
                                            },
                                        },
                                        {
                                            type: "link",
                                            label: "Favourite Types",
                                            visibility: this.isInRole(
                                                roles.admin()
                                            ),
                                            route: "/main/favourite-types/favourite-types-grid",
                                            icon: "mat:group",
                                            routerLinkActiveOptions: {
                                                exact: true,
                                            },
                                        },
                                        {
                                            type: "link",
                                            label: "Place Of Service",
                                            visibility: this.isInRole(
                                                roles.admin()
                                            ),
                                            route: "/main/pos/place-of-service-grid",
                                            icon: "mat:location_on",
                                            routerLinkActiveOptions: {
                                                exact: true,
                                            },
                                        },
                                        {
                                            type: "link",
                                            label: "Cpt Code Types",
                                            icon: "mat:key",
                                            visibility: this.isInRole(
                                                roles.admin()
                                            ),
                                            route: "/main/cpt-code-type/cpt-code-types-grid",
                                            routerLinkActiveOptions: {
                                                exact: true,
                                            },
                                        },
                                        {
                                            type: "link",
                                            label: "Cpt Codes",
                                            icon: "mat:password",
                                            visibility: this.isInRole(
                                                roles.admin()
                                            ),
                                            route: "/main/cpt-codes/cpt-codes-grid",
                                            routerLinkActiveOptions: {
                                                exact: true,
                                            },
                                        },
                                        {
                                            type: "link",
                                            label: "Icd Codes",
                                            icon: "mat:key",
                                            visibility: this.isInRole(
                                                roles.admin()
                                            ),
                                            route: "/main/icd-codes/icd-codes-grid",
                                            routerLinkActiveOptions: {
                                                exact: true,
                                            },
                                        },
                                        {
                                            type: "link",
                                            label: "Forms",
                                            icon: "mat:list_alt",
                                            visibility: this.isInRole(
                                                roles.admin()
                                            ),
                                            route: "/main/forms/forms-grid",
                                            routerLinkActiveOptions: {
                                                exact: false,
                                            },
                                        },
                                        {
                                            type: "dropdown",
                                            label: "Field",
                                            visibility: this.isInRole(
                                                roles.admin()
                                            ),
                                            icon: "mat:app_registration",
                                            children: [
                                                {
                                                    type: "link",
                                                    label: "Data Type",
                                                    visibility: this.isInRole(
                                                        roles.admin()
                                                    ),
                                                    route: "/main/fields/data-types-grid",
                                                    routerLinkActiveOptions: {
                                                        exact: true,
                                                    },
                                                },
                                                {
                                                    type: "link",
                                                    label: "Fields",
                                                    visibility: this.isInRole(
                                                        roles.admin()
                                                    ),
                                                    route: "/main/fields/fields-grid",
                                                    routerLinkActiveOptions: {
                                                        exact: true,
                                                    },
                                                },
                                                {
                                                    type: "link",
                                                    label: "Fields Type",
                                                    visibility: this.isInRole(
                                                        roles.admin()
                                                    ),
                                                    route: "/main/fields/fields-type-grid",
                                                    routerLinkActiveOptions: {
                                                        exact: true,
                                                    },
                                                },
                                            ],
                                        },
                                        {
                                            type: "link",
                                            label: "Global Settings",
                                            visibility: this.isInRole(
                                                roles.admin()
                                            ),
                                            route: "/main/settings/global-settings",
                                            icon: "mat:settings",
                                        },
                                    ],
                                },
                            ],
                        },
                    ];
                    this.filterVisibleMenuItems(this.navigationService.items);
                    this.adminService.isNavigationChangeSubject.next(true);
                } else {
                }
            },
            (err) => {}
        );
    }

    isInRole(role_name) {
        if (
            this.currentRoles != undefined &&
            this.currentRoles != null &&
            this.currentRoles.indexOf(role_name) >= 0
        ) {
            return true;
        }
        return false;
    }

    tokenExpirationCheck() {
        let currentTime = new Date();
        let futureTime = this.oauthService.getAccessTokenExpiration();
        let timeDifference = futureTime - currentTime.getTime();
        if (timeDifference > 0) {
            this.timeOutClear = setTimeout(() => {
                this.sessionExpirationWarning();
            }, timeDifference - 300000);
        }
        if (timeDifference >= 0) {
            this.tokenTimeOut = setTimeout(() => {
                this.sessionExpired();
            }, timeDifference);
        } else {
            clearInterval(this.tokenTimeOut);
        }
    }

    filterVisibleMenuItems(menuItem: any) {
        return menuItem.filter((o) => {
            if (o.children)
                o.children = this.filterVisibleMenuItems(o.children);
            return o.visibility == true;
        });
    }

    configureAuthService() {
        this.oauthService.configure(authConfigData);
        // this.oauthService.tokenValidationHandler=new JwksValidationHandler();
        // this.oauthService.timeoutFactor=.75
        // this.oauthService.setupAutomaticSilentRefresh();
        this.oauthService.loadDiscoveryDocumentAndTryLogin();
    }

    sessionExpirationWarning(): void {
        let title="Session Expire"
        let sessionExpiratonMessage = "Your session will expire in 5 minutes";
        // "Your session expired. Click 'OK' to login again";
        let imgUrl = "../../../assets/img/illustrations/session-expiration-5-minutes.jpg";
        // let imgUrl = "../../../assets/img/illustrations/session-expired.jpg";
        sweetAlertWarning(title,sessionExpiratonMessage, imgUrl);
    }

    sessionExpired() {
        let sessionExpiratonMessage =
            "Your session expired. Click 'OK' to login again";
        let title="Session Expired"
        // let imgUrl =
        //   "../../../assets/img/illustrations/session-expiration-5-minutes.jpg";
        let imgUrl = "../../../assets/img/illustrations/session-expired.jpg";
        sweetAlertWarning(title,sessionExpiratonMessage, imgUrl).then((isExpired) => {
            if (isExpired.value) {
                this.router.navigate(["auth/login"]);
            }
        });
    }
}
