import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class MainService {
  checkUser = new Subject();
  checkLogged = new BehaviorSubject(false);
  currentUser = this.checkUser.asObservable();
  isLoggedIn = this.checkLogged.asObservable();
  constructor() {}
}
