import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SpinnerService, SpinnerState } from '../../services/spinner.service';

@Component({
  selector: "medmio-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.scss"],
})
export class SpinnerComponent implements OnInit {
  show: boolean = false;
  private spinnerStateChanged: Subscription;
  constructor(private spinnerService: SpinnerService) {}

  ngOnInit(){
          this.spinnerStateChanged = this.spinnerService.spinnerState.subscribe(
            (state: SpinnerState) => {
              this.show = state.show;
            }
          );
  }
}
