<div class="card">
  <div class="border-b py-4 px-6 flex items-center">
    <h2 class="m-0 title flex-auto">Billing Analytics</h2>

    <button mat-icon-button type="button">
      <mat-icon class="text-secondary" svgIcon="mat:cloud_download"></mat-icon>
    </button>

    <button mat-icon-button type="button">
      <mat-icon class="text-secondary" svgIcon="mat:more_horiz"></mat-icon>
    </button>
  </div>

  <div class="pt-3 pb-1 pr-6">
    <vex-chart [options]="options" [series]="series"></vex-chart>
  </div>
</div>
