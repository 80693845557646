import Swal from "sweetalert2"

export const sweetAlert:any=()=>{
    return Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#6E7881",
        confirmButtonText: "Yes, delete it!",
    })
}
export const sweetAlertWarning:any=(title:string,txtMessage:any,imgUrl:string)=>{
    return Swal.fire({
      title: title,
      text: txtMessage,
      imageUrl:imgUrl,
      imageWidth: 200,
      imageHeight: 200,
      imageAlt: "Session expire",
      confirmButtonColor: "#00bcd4",
      //   confirmButtonText: "",
    });
}


// import secondsToHours from "../../../assets/img/illustrations/idea.svg";