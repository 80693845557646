import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import { CanActivateGuard } from './shared/guards/can-activate.guard';

const routes: Routes = [
  { path: "", pathMatch: "full", redirectTo: "auth" },
  {
    path: "auth",
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "main",
    canLoad: [CanActivateGuard],
    canActivate: [CanActivateGuard],
    loadChildren: () => import("./main/main.module").then((m) => m.MainModule),
  },
  { path: "**", redirectTo: "auth" },
  // {
  //   path: "",
  //   component: MainLayoutComponent,
  //   children: [],
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
