<div [class.container]="isVerticalLayout$ | async" class="toolbar w-full px-gutter flex items-center">
  <button (click)="openSidenav()" [class.hidden]="!mobileQuery" mat-icon-button type="button">
    <mat-icon svgIcon="mat:menu"></mat-icon>
  </button>

  <a *ngIf="isVerticalLayout$ | async"
     [routerLink]="['/']"
     class="ltr:mr-4 rtl:ml-4 block flex items-center">
    <img alt="Logo" class="w-8 select-none" src="assets/img/demo/logo.svg"/>
    <h1 [class.hidden]="!mobileQuery" class="title ltr:pl-4 rtl:pr-4 m-0 select-none">medmio</h1>
  </a>

  <!-- <button *ngIf="isHorizontalLayout$ | async"
          [class.hidden]="mobileQuery"
          color="primary"
          mat-flat-button
          [matMenuTriggerFor]="addNewMenu"
          type="button">Add New
  </button> -->


  <div *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async"
       [class.hidden]="mobileQuery"
       class="px-gutter flex-none flex items-center">
    <vex-navigation-item *ngFor="let item of navigationItems" [item]="item"></vex-navigation-item>
  </div>

  <span class="flex-1"></span>

  <div class="-mx-1 flex items-center">
    <div class="px-1">
      <!-- <mat-form-field *ngIf="isAdmin" appearance="fill" class="org-dropdown">
        <mat-label>Select Organization</mat-label>
        <mat-select (selectionChange)="changeOrganization()"  [(value)]="selectedOrganization">
          <mat-option *ngFor="let org of orgList" [value]="org.Key">{{org.Value}}</mat-option>
        </mat-select>
        </mat-form-field> -->
    </div>

    <!-- <div class="px-1">
      <vex-toolbar-notifications></vex-toolbar-notifications>
    </div>

    <div class="px-1">
      <button (click)="openQuickpanel()" mat-icon-button type="button">
        <mat-icon color="primary" svgIcon="mat:bookmarks"></mat-icon>
      </button>
    </div>

    <div class="px-1">
      <button mat-icon-button type="button">
        <mat-icon svgIcon="flag:united-states"></mat-icon>
      </button>
    </div> -->

    <div *ngIf="userVisible$ | async" class="px-1">
      <vex-toolbar-user></vex-toolbar-user>
    </div>

  </div>
</div>

<!-- <vex-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async"
                [class.hidden]="mobileQuery"></vex-navigation> -->
