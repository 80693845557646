import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Location } from "@angular/common";

@Component({
  selector: 'medmio-ui-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {
  @Input () layoutCtrlValue:any="boxed"; 
  @Input () pageHeader:any=""; 
  @Input () crumbValue:any=[]; 
  @Input () orgDropDownVisible:boolean=false; 
  @Input () backButtonVisible:boolean=false; 
  layoutCtrl = new UntypedFormControl(this.layoutCtrlValue);
  constructor(private location:Location) { }

  ngOnInit(): void {
  }

  goBack() {
    this.location.back();
}

}
