import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree,
} from "@angular/router";
import { OAuthService } from "angular-oauth2-oidc";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class CanActivateGuard implements CanActivate {
    constructor(private router: Router, private oauthService: OAuthService) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.checkLogin();
    }
    canLoad(
        route: Route,
        segments: UrlSegment[]
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.checkLogin();
    }
    checkLogin(): boolean {
        // debugger
        // let hasToken = this.oauthService.hasValidIdToken();
        // let hasValidToken = this.oauthService.hasValidAccessToken();
        // // let hasAccessToken = hasToken && hasValidToken;
        // if (this.oauthService.getIdentityClaims() !== null){
        //   if (!hasValidToken) {
        //     this.router.navigate(["/auth/login"]);
        //     return false;
        //   }
        // }
        // else{
        //   this.router.navigate(["/auth/login"]);
        //   return false;
        // }
        //   return true
        // debugger;
        if (this.oauthService.getIdentityClaims !== null) {
            let currentTime = new Date();
            let futureTime = this.oauthService.getAccessTokenExpiration();
            let timeDifference = futureTime
                ? futureTime - currentTime.getTime()
                : 0;
            if (timeDifference > 0) {
                return true;
            } else {
                this.router.navigate(["/auth/login"]);
                return false;
            }
        } else {
            this.router.navigate(["/auth/login"]);
            return false;
        }
    }
}
