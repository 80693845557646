import { environment } from "src/environments/environment";

export const authConfigData = {
  issuer: environment.identity(),

  redirectUri: window.location.origin + "/#/auth/verify",

  postLogoutRedirectUri: window.location.origin + "/#/auth/login",

  // clientId: environment.getClientId(),

  clientId: environment.clientId(),

  responseType: "code",

  requireHttps: true,

  scope: "openid profile portal.user ",

  showDebugInformation: true,
};