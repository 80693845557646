import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map, Observable, throwError } from "rxjs";
import { ApiUrls } from "src/app/config/api-url-config";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  constructor(private httpClient: HttpClient) {}

  UsersGetAllObservable(): Observable<any> {
    return this.httpClient
        .get(environment.apiUrlBase() + ApiUrls.UserGetAll())
        .pipe(
            map((body: any) => body),
            catchError((error: any) =>
                throwError(error || "Error, while loading data ")
            )
        );
  }

  UsersAddObservable(request: any): Observable<any> {
    return this.httpClient
      .post(environment.apiUrlBase() + ApiUrls.UserAdd(), request)
      .pipe(
        map((body: any) => body),
        catchError((error: any) =>
          throwError(error || "Error, while loading data ")
        )
      );
  }

  UsersAddClaims(id:any,request: any): Observable<any> {
    return this.httpClient
      .post(environment.apiUrlBase() + ApiUrls.AddAllowedApps(id), request)
      .pipe(
        map((body: any) => body),
        catchError((error: any) =>
          throwError(error || "Error, while loading data ")
        )
      );
  }
  RemoveUsersClaims(request: any): Observable<any> {
    return this.httpClient
      .post(environment.apiUrlBase() + ApiUrls.RemoveUserClaims(), request)
      .pipe(
        map((body: any) => body),
        catchError((error: any) =>
          throwError(error || "Error, while loading data ")
        )
      );
  }

  UsersUpdateObservable(request: any): Observable<any> {
    return this.httpClient
      .post(environment.apiUrlBase() + ApiUrls.UserUpdateUser(), request)
      .pipe(
        map((body: any) => body),
        catchError((error: any) =>
          throwError(error || "Error, while loading data ")
        )
      );
  }

  UsersGetByIdObservable(userId: any): Observable<any> {
    return this.httpClient
      .get(environment.apiUrlBase() + ApiUrls.UserGetById(userId))
      .pipe(
        map((body: any) => body),
        catchError((error: any) =>
          throwError(error || "Error, while loading data ")
        )
      );
  }

  UsersAddRolesObservable(request: any): Observable<any> {
    return this.httpClient
      .post(environment.apiUrlBase() + ApiUrls.UserRolesAdd(),request)
      .pipe(
        map((body: any) => body),
        catchError((error: any) =>
          throwError(error || "Error, while loading data ")
        )
      );
  }

  UsersRemoveObservable(userId: any): Observable<any> {
    return this.httpClient
      .delete(environment.apiUrlBase() + ApiUrls.UserRemove(userId))
      .pipe(
        map((body: any) => body),
        catchError((error: any) =>
          throwError(error || "Error, while loading data ")
        )
      );
  }

  UsersKeyValueObservable(): Observable<any> {
    return this.httpClient
      .get(environment.apiUrlBase() + ApiUrls.UserGetKeyPairValues())
      .pipe(
        map((body: any) => body),
        catchError((error: any) =>
          throwError(error || "Error, while loading data ")
        )
      );
  }

  UsersSettingsGetallObservable(userId): Observable<any> {
    return this.httpClient
      .get(environment.apiUrlBase() + ApiUrls.UserSettingsGetAll(userId))
      .pipe(
        map((body: any) => body),
        catchError((error: any) =>
          throwError(error || "Error, while loading data ")
        )
      );
  }

  UsersSettingsAddObservable(userId, request): Observable<any> {
    return this.httpClient
      .post(environment.apiUrlBase() + ApiUrls.UserSettingsAdd(userId), request)
      .pipe(
        map((body: any) => body),
        catchError((error: any) =>
          throwError(error || "Error, while loading data ")
        )
      );
  }

  UsersSettingsGetByIdObservable(userSettingId): Observable<any> {
    return this.httpClient
      .get(
        environment.apiUrlBase() + ApiUrls.UserSettingsGetById(userSettingId)
      )
      .pipe(
        map((body: any) => body),
        catchError((error: any) =>
          throwError(error || "Error, while loading data ")
        )
      );
  }

  UsersSettingsRemoveObservable(userSettingId): Observable<any> {
    return this.httpClient
      .delete(
        environment.apiUrlBase() + ApiUrls.UserSettingsRemove(userSettingId)
      )
      .pipe(
        map((body: any) => body),
        catchError((error: any) =>
          throwError(error || "Error, while loading data ")
        )
      );
  }

  UsersSettingsKeyValueObservable(userSettingId): Observable<any> {
    return this.httpClient
      .get(environment.apiUrlBase() + ApiUrls.UserSettingsGetKeyPairValues())
      .pipe(
        map((body: any) => body),
        catchError((error: any) =>
          throwError(error || "Error, while loading data ")
        )
      );
  }

  UsersSettingsGetByKeyObservable(settingKey: any): Observable<any> {
    return this.httpClient
      .get(
        environment.apiUrlBase() +
          ApiUrls.UserSettingsGetBySettingKey(settingKey)
      )
      .pipe(
        map((body: any) => body),
        catchError((error: any) =>
          throwError(error || "Error, while loading data ")
        )
      );
  }

  UsersGetUserRoles(userId: any): Observable<any> {
    return this.httpClient
      .get(
        environment.apiUrlBase() +
          ApiUrls.GetUserRoles(userId)
      )
      .pipe(
        map((body: any) => body),
        catchError((error: any) =>
          throwError(error || "Error, while loading data ")
        )
      );
  }
  GetUserClaims(userId: any): Observable<any> {
    return this.httpClient
      .get(
        environment.apiUrlBase() +
          ApiUrls.GetUserClaims(userId)
      )
      .pipe(
        map((body: any) => body),
        catchError((error: any) =>
          throwError(error || "Error, while loading data ")
        )
      );
  }
  GetUserAllowedApps(userId: any): Observable<any> {
    return this.httpClient
      .get(
        environment.apiUrlBase() +
          ApiUrls.GetUserAllowedApps(userId)
      )
      .pipe(
        map((body: any) => body),
        catchError((error: any) =>
          throwError(error || "Error, while loading data ")
        )
      );
  }

  RemoveUserRoles(request: any): Observable<any> {
    return this.httpClient
      .post(
        environment.apiUrlBase() +
          ApiUrls.RemoveUserRoles(),request
      )
      .pipe(
        map((body: any) => body),
        catchError((error: any) =>
          throwError(error || "Error, while loading data ")
        )
      );
  }

  UserPasswordReset(request: any): Observable<any> {
    return this.httpClient
      .post(
        environment.apiUrlBase() +
          ApiUrls.PasswordResetUrl(),request
      )
      .pipe(
        map((body: any) => body),
        catchError((error: any) =>
          throwError(error || "Error, while loading data ")
        )
      );
  }
  GetAllRoles(): Observable<any> {
    return this.httpClient
      .get(
        environment.apiUrlBase() +
          ApiUrls.UserGetAllRoles()
      )
      .pipe(
        map((body: any) => body),
        catchError((error: any) =>
          throwError(error || "Error, while loading data ")
        )
      );
  }
  GetRolesByUserId(userId:any): Observable<any> {
    return this.httpClient
      .get(
        environment.apiUrlBase() +
          ApiUrls.UserGetRolesByUserId(userId)
      )
      .pipe(
        map((body: any) => body),
        catchError((error: any) =>
          throwError(error || "Error, while loading data ")
        )
      );
  }
  GetCurrentUserRole(): Observable<any> {
    return this.httpClient
      .get(
        environment.apiUrlBase() +
          ApiUrls.CurrentUserRole()
      )
      .pipe(
        map((body: any) => body),
        catchError((error: any) =>
          throwError(error || "Error, while loading data ")
        )
      );
  }

  GetCurrentOrgId(): Observable<any> {
    return this.httpClient
      .get(
        environment.apiUrlBase() +
          ApiUrls.UserGetCurrentOrganizationId()
      )
      .pipe(
        map((body: any) => body),
        catchError((error: any) =>
          throwError(error || "Error, while loading data ")
        )
      );
  }
}
