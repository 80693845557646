import { Component, ElementRef, HostBinding, Input } from "@angular/core";
import { LayoutService } from "../../../services/layout.service";
import { ConfigService } from "../../../config/config.service";
import { map, startWith, switchMap } from "rxjs/operators";
import { NavigationService } from "../../../services/navigation.service";
import { PopoverService } from "../../components/popover/popover.service";
import { MegaMenuComponent } from "../../components/mega-menu/mega-menu.component";
import { Observable, of } from "rxjs";
import { AdminServiceService } from "src/app/shared/services/admin-service.service";
import { OrganizationCrudService } from "src/app/shared/services/api-services/organization-crud.service";
import { UsersService } from "src/app/shared/services/api-services/users.service";

@Component({
    selector: "vex-toolbar",
    templateUrl: "./toolbar.component.html",
    styleUrls: ["./toolbar.component.scss"],
})
export class ToolbarComponent {
    selectedOrganization = "";
    orgList: any = [];
    @Input() mobileQuery: boolean;
    isAdmin: boolean;

    @Input()
    @HostBinding("class.shadow-b")
    hasShadow: boolean;

    navigationItems = this.navigationService.items;

    isHorizontalLayout$: Observable<boolean> = this.configService.config$.pipe(
        map((config) => config.layout === "horizontal")
    );
    isVerticalLayout$: Observable<boolean> = this.configService.config$.pipe(
        map((config) => config.layout === "vertical")
    );
    isNavbarInToolbar$: Observable<boolean> = this.configService.config$.pipe(
        map((config) => config.navbar.position === "in-toolbar")
    );
    isNavbarBelowToolbar$: Observable<boolean> =
        this.configService.config$.pipe(
            map((config) => config.navbar.position === "below-toolbar")
        );
    userVisible$: Observable<boolean> = this.configService.config$.pipe(
        map((config) => config.toolbar.user.visible)
    );
    count: number = 0;
    countAdmin: number = 0;
    megaMenuOpen$: Observable<boolean> = of(false);
    constructor(
        private layoutService: LayoutService,
        private configService: ConfigService,
        private navigationService: NavigationService,
        private popoverService: PopoverService,
        private adminService: AdminServiceService,
        private orgService: OrganizationCrudService,
        private userService: UsersService
    ) {}

    ngOnInit() {
        this.isAdminCheck();

    }
    openQuickpanel(): void {
        this.layoutService.openQuickpanel();
    }

    isAdminCheck() {
        this.adminService.isAdmin$.subscribe((res) => {
            this.isAdmin = res;
            if (this.isAdmin) {
                if (this.countAdmin == 0) {
                    this.getOrganizationKeyValue();
                    this.getCurrentOrganization();
                }
            }
        });
    }

    getOrganizationKeyValue() {
        this.orgService.OrganizationGetKeyValueApi().subscribe(
            (res) => {
                if (!res.IsError) {
                    this.orgList = res.Result;
                    this.adminService.organizationOptions.next(this.orgList)
                    this.getCurrentOrganization;
                } else {
                }
            },
            (err) => {}
        );
    }

    getCurrentOrganization() {
        this.userService.GetCurrentOrgId().subscribe(
            (res) => {
                if (!res.IsError) {
                    if (this.count == 0) {
                        // debugger
                        this.selectedOrganization = res.Result;
                        this.changeOrganization()
                    }
                } else {
                    this.selectedOrganization = this.orgList[0].Key;
                    this.changeOrganization()
                }

            },
            (err) => {}
        );
    }

    changeOrganization() {
        // debugger
        sessionStorage.setItem("orgId", this.selectedOrganization);
        this.adminService.organizationIdSubject.next(this.selectedOrganization);
    }

    openSidenav(): void {
        this.layoutService.openSidenav();
    }

    openMegaMenu(origin: ElementRef | HTMLElement): void {
        this.megaMenuOpen$ = of(
            this.popoverService.open({
                content: MegaMenuComponent,
                origin,
                offsetY: 12,
                position: [
                    {
                        originX: "start",
                        originY: "bottom",
                        overlayX: "start",
                        overlayY: "top",
                    },
                    {
                        originX: "end",
                        originY: "bottom",
                        overlayX: "end",
                        overlayY: "top",
                    },
                ],
            })
        ).pipe(
            switchMap((popoverRef) =>
                popoverRef.afterClosed$.pipe(map(() => false))
            ),
            startWith(true)
        );
    }

    openSearch(): void {
        this.layoutService.openSearch();
    }
}
